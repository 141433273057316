import React, { useState, useEffect, useRef, useCallback } from 'react';
import Shelf from './Shelf';
import { getBookData } from '../data/bookData';

const bookcaseStyle = {
    backgroundColor: '#2c1e16',
    padding: '40px 20px',
    boxShadow: '0 0 50px rgba(0,0,0,0.8) inset',
    width: '100%',
    margin: '0 auto',
    borderRadius: '10px',
    boxSizing: 'border-box',
    position: 'relative',
    overflow: 'hidden',
};

const bookcaseOverlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `
    linear-gradient(45deg, rgba(0,0,0,0.1) 25%, transparent 25%, transparent 75%, rgba(0,0,0,0.1) 75%, rgba(0,0,0,0.1)),
    linear-gradient(-45deg, rgba(0,0,0,0.1) 25%, transparent 25%, transparent 75%, rgba(0,0,0,0.1) 75%, rgba(0,0,0,0.1))
    `,
    backgroundSize: '60px 60px',
    pointerEvents: 'none',
};

const sortingButtonsStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
};

const buttonStyle = {
    margin: '0 10px',
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#8B4513',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
};

const activeButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#A0522D',
};

const bookHoverStyle = `
  .book-wrapper {
    z-index: 0;
    transition: z-index 0s 0.5s;
  }
  .book-wrapper:hover {
    z-index: 1;
    transition: z-index 0s;
  }
  .book-wrapper:hover .book {
    transform: translateX(-100px) translateZ(100px) rotateY(-90deg);
    box-shadow: 20px 20px 20px rgba(0,0,0,0.4);
  }
`;

const Bookcase = () => {
    const [books, setBooks] = useState([]);
    const [shelves, setShelves] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortBy, setSortBy] = useState('isle');
    const bookcaseRef = useRef(null);

    useEffect(() => {
        const fetchBooks = async () => {
            try {
                const fetchedBooks = await getBookData();
                setBooks(fetchedBooks);
                setIsLoading(false);
            } catch (err) {
                console.error("Error fetching books:", err);
                setError(`Failed to load books. Error: ${err.message}`);
                setIsLoading(false);
            }
        };

        fetchBooks();
    }, []);

    const sortBooks = useCallback((books, sortBy) => {
        const groupAndSortBooks = (books, getCategory) => {
            const categories = {};
            books.forEach(book => {
                const category = getCategory(book);
                if (!categories[category]) {
                    categories[category] = [];
                }
                categories[category].push(book);
            });

            // Sort categories by number of books, descending
            const sortedCategories = Object.entries(categories)
                .sort(([a], [b]) => {
                    if (a === 'E') return 1;
                    if (b === 'E') return -1;
                    return categories[b].length - categories[a].length;
                })
                .map(([category, books]) => ({ category, books }));

            // Move "No Tags" or empty category to the end, but before isle E
            const noTagsIndex = sortedCategories.findIndex(c => c.category === '' || c.category === 'No Tags');
            if (noTagsIndex !== -1) {
                const noTags = sortedCategories.splice(noTagsIndex, 1)[0];
                const eIndex = sortedCategories.findIndex(c => c.category === 'E');
                if (eIndex !== -1) {
                    sortedCategories.splice(eIndex, 0, noTags);
                } else {
                    sortedCategories.push(noTags);
                }
            }

            return sortedCategories;
        };

        switch (sortBy) {
            case 'date':
                return [...books].sort((a, b) => new Date(b.created) - new Date(a.created));
            case 'tags':
                return groupAndSortBooks(books.filter(book => book.isle !== 'D'), book => (book.tags && book.tags.length > 0) ? book.tags[0] : 'No Tags');
            case 'isle':
            default:
                return groupAndSortBooks(books, book => book.isle || 'No Isle');
        }
    }, []);

    const organizeBooksIntoShelves = useCallback((sortedBooks) => {
        if (!bookcaseRef.current) return;

        const shelfWidth = bookcaseRef.current.offsetWidth - 80; // Accounting for padding
        const newShelves = [];

        const addShelf = (books, category = null) => {
            let currentShelf = [];
            let currentWidth = 0;

            if (category) {
                currentShelf.push({
                    isIsleNote: true,
                    isle: category,
                    width: 100
                });
                currentWidth += 100;
            }

            books.forEach((book) => {
                const bookWidth = 50; // Fixed width for all books
                if (currentWidth + bookWidth > shelfWidth) {
                    newShelves.push(currentShelf);
                    currentShelf = [];
                    currentWidth = 0;
                }
                currentShelf.push({ ...book, width: bookWidth });
                currentWidth += bookWidth;
            });

            if (currentShelf.length > 0) {
                newShelves.push(currentShelf);
            }
        };

        if (sortBy === 'date') {
            addShelf(sortedBooks.filter(book => book.isle !== 'D'));
        } else {
            sortedBooks.forEach(({ category, books }) => {
                if (sortBy === 'isle' || category !== 'D') {
                    addShelf(books, category);
                }
            });
        }

        setShelves(newShelves);
    }, [sortBy, bookcaseRef]);

    useEffect(() => {
        const sortedBooks = sortBooks(books, sortBy);
        organizeBooksIntoShelves(sortedBooks);
    }, [books, sortBy, sortBooks, organizeBooksIntoShelves]);

    if (isLoading) return <div>Loading books...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div ref={bookcaseRef} style={bookcaseStyle}>
            <div style={bookcaseOverlayStyle}></div>
            <style>{bookHoverStyle}</style>
            <div style={sortingButtonsStyle}>
                <button
                    style={sortBy === 'isle' ? activeButtonStyle : buttonStyle}
                    onClick={() => setSortBy('isle')}
                >
                    Sort by Isle
                </button>
                <button
                    style={sortBy === 'date' ? activeButtonStyle : buttonStyle}
                    onClick={() => setSortBy('date')}
                >
                    Sort by Date
                </button>
                <button
                    style={sortBy === 'tags' ? activeButtonStyle : buttonStyle}
                    onClick={() => setSortBy('tags')}
                >
                    Sort by Tags
                </button>
            </div>
            {shelves.map((shelfBooks, index) => (
                <Shelf key={index} books={shelfBooks} />
            ))}
        </div>
    );
};

export default Bookcase;