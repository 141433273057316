import React from 'react';
import Book from './Book';
import { getRandomInt } from '../utils/helpers';

const shelfBackStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#8B4513',
    backgroundImage: `
    repeating-linear-gradient(
      90deg,
      rgba(255,255,255,0.1),
      rgba(255,255,255,0.1) 10px,
      transparent 10px,
      transparent 20px
    )
  `,
};

const shelfBottomStyle = {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: '20px',
    backgroundColor: '#8B4513',
    backgroundImage: `
    linear-gradient(
      0deg,
      rgba(0,0,0,0.2) 0%,
      transparent 20%,
      rgba(255,255,255,0.1) 40%,
      transparent 80%,
      rgba(0,0,0,0.2) 100%
    )
  `,
    boxShadow: '0 2px 10px rgba(0,0,0,0.3)',
};

const shelfStyle = {
    position: 'relative',
    height: '320px',
    marginBottom: '40px',
};

const booksContainerStyle = {
    display: 'flex',
    alignItems: 'flex-end',
    position: 'absolute',
    bottom: '20px',
    left: '10px',
    right: '10px',
    height: '290px',
};

const isleNoteStyle = {
    width: '100px',
    height: '150px',
    backgroundColor: '#f0e68c',
    border: '1px solid #daa520',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#8b4513',
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
    marginRight: '10px',
    padding: '10px',
    textAlign: 'center',
};

const Shelf = ({ books }) => {
    console.log('Rendering Shelf with books:', books);
    return (
        <div style={shelfStyle}>
            <div style={shelfBackStyle}></div>
            <div style={shelfBottomStyle}></div>
            <div style={booksContainerStyle}>
                {books.map((book, index) => {
                    if (book.isIsleNote) {
                        let isleDescription;
                        switch (book.isle) {
                            case 'A':
                                isleDescription = "Insightful Thinking";
                                break;
                            case 'B':
                                isleDescription = "Useful Tools";
                                break;
                            case 'C':
                                isleDescription = "Utilities";
                                break;
                            case 'D':
                                isleDescription = "Important things that I did not come up with but would like to remember";
                                break;
                            case 'E':
                                isleDescription = "Very old and hard for me to relate to";
                                break;
                            default:
                                isleDescription = `${book.isle}`;
                        }
                        return (
                            <div key={`isle-${book.isle}`} style={isleNoteStyle}>
                                {isleDescription}
                            </div>
                        );
                    }

                    const tiltAngle = index % 10 === 0 ? getRandomInt(-2, 2) : 0;
                    const isEnd = index === 0 || index === books.length - 1;
                    return (
                        <Book
                            key={book.id}
                            {...book}
                            height={getRandomInt(220, 290)}
                            tiltAngle={tiltAngle}
                            isEnd={isEnd}
                            index={index}
                            style={{ marginRight: '2px' }}
                            coverImage={book.cover}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Shelf;